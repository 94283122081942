<template>
  <div class="mobile-simulator">
    <iframe ref="iframe" frameborder="0" :src="demoUrl" />
    <div class="mobile-simulator__footer">
      <cube-popover
        placement="top"
        width="96"
        popper-class="qrcode"
        trigger="click"
      >
        <canvas id="QRCode_header" style="width: 96px; height: 96px" />
        <span>手机扫码查看</span>
        <cube-icon
          slot="reference"
          name="cube-qrcode-outline"
          width="20px"
          height="20px"
        />
      </cube-popover>
    </div>
  </div>
</template>
<script>
// import { eventLisener } from "../../utils/index"
import QRCode from "qrcode" // 引入生成二维码插件

export default {
  name: "MobileSimulator",
  beforeRouteUpdate(to, from, next) {
    // console.log("to", to)
    // this.$refs.iframe.contentWindow.scrollTo(0, 0)
    const toPath = to.path
    const fromPath = from.path
    if (toPath !== fromPath) {
      this.bodyContent.scrollTop = 0
    }
    next()
    this.$nextTick(() => {
      this.goAnchor()
      if (toPath !== fromPath) {
        this.renderAnchorHref()
      }
    })
    // eventLisener("asyncComponentLoaded", () => {
    //   this.$nextTick(() => {
    //     this.goAnchor()
    //     if (toPath !== fromPath) {
    //       this.renderAnchorHref()
    //     }
    //   })
    // })
  },
  props: {
    demoUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      bodyContent: null
    }
  },
  computed: {},
  watch: {
    demoUrl(newVal) {
      if (newVal) this.getQRCode()
    }
  },
  mounted() {
    this.bodyContent = document.querySelector(".content-container")
    this.renderAnchorHref()
    this.goAnchor()
    this.getQRCode()
  },
  methods: {
    getQRCode() {
      const opts = {
        errorCorrectionLevel: "H", // 容错级别
        type: "image/png", // 生成的二维码类型
        quality: 0.3, // 二维码质量
        margin: 0, // 二维码留白边距
        width: 96, // 宽
        height: 96, // 高
        text: "", // 二维码内容
        color: {
          dark: "#333333", // 前景色
          light: "#fff" // 背景色
        }
      }

      const msg = document.getElementById("QRCode_header")
      // 将获取到的数据(val)画到msg(canvas)上
      QRCode.toCanvas(msg, this.demoUrl, opts, function(error) {
        if (error) {
          console.log("二维码加载失败", error)
        }
      })
    },
    renderAnchorHref() {
      const anchors = document.querySelectorAll("h2 a,h3 a,h4 a,h5 a")
      const basePath = location.href.split("#").splice(0, 2).join("#")

      Array.prototype.slice.call(anchors).forEach((a) => {
        const href = a.getAttribute("href")
        a.href = href.indexOf(basePath) > -1 ? href : basePath + href
      })
    },
    goAnchor() {
      const locationArr = location.href.match(/#/g)
      if (locationArr && locationArr.length > 1) {
        const anchor = location.href.match(/#[^#]+$/g)
        if (!anchor) return
        const elm = document.querySelector(anchor[0])
        if (!elm) return

        this.$nextTick(() => {
          this.bodyContent.scrollTop = elm.offsetTop
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "./index.scss";
.el-popover.qrcode {
  min-width: 120px;
  text-align: center;
}
</style>
