<template>
  <div v-if="this.$site.showVersion" class="nav-version">
    <cube-dropdown class="nav-dropdown" trigger="click">
      <span class="nav-version__link">
        {{ version }}
        <cube-icon name="cube-caret-down-outline" color="#ccc" width="16px" height="16px" />
      </span>
      <cube-dropdown-menu slot="dropdown" class="nav-dropdown__list">
        <cube-dropdown-item
          v-for="item in versionData"
          :key="item"
          @click.native="switchVersion(item)"
        >
          <span
            class="nav-dropdown__list__text"
            :class="{ active: version == item }"
          >{{ item }}</span>
        </cube-dropdown-item>
      </cube-dropdown-menu>
    </cube-dropdown>
    <!-- <DropdownLink :item="item" /> -->
  </div>
</template>

<script>
import { versionConfig } from "config/version/index"
import { getAppVersionCompared } from "../../utils"

export default {
  name: "VersionMenu",
  data() {
    return {
      verDropdownVisible: true,
      version: "", // 版本选择的值
      nowVersion: "", // 当前的版本号
      versions: {},
      defaultVersion: {}, // 默认当前项目的版本号
      cubeVersion: {}, // cube的版本下拉组件
      horizonVersion: {} // horizon的版本下拉组件
    }
  },
  computed: {
    versionData() {
      return this.getVersionData()
    }
  },
  watch: {
    $route(to, from) {
      this.getVersions(to.path)
    }
  },
  created() {
    this.init()
    this.getVersions(this.$route.path)
  },
  methods: {
    init() {
      this.getVersion()
      versionConfig.sort((a, b) => {
        return getAppVersionCompared(a.text, b.text)
      })
      versionConfig.forEach((item) => {
        if (!this.defaultVersion[item.text]) {
          this.defaultVersion[item.text] = {
            text: item.text,
            link: item.link || null
          }
        }
        if (item.items && !this.horizonVersion[item.items.horizon]) {
          this.horizonVersion[item.items.horizon] = {
            text: item.text,
            link: item.link || null
          }
        }
        if (item.items && !this.cubeVersion[item.items.cube]) {
          this.cubeVersion[item.items.cube] = {
            text: item.text,
            link: item.link || null
          }
        }
      })
    },
    getVersion() {
      const arr = location.href.split("/")
      const versionRegexp = /^(?:\d+\.){2}\d+$/
      const index = arr.findIndex((item) => {
        return versionRegexp.test(item)
      })
      console.log(index, "dwa")
      this.nowVersion = index > -1 ? arr[index] : ""
    },
    getVersions(path) {
      this.version = []
      // 获取下拉组件数据 cube下为cube版本 horizon为horizon版本 默认为项目主版本
      if (path.includes("/docs/components/cube")) {
        this.versions = JSON.parse(JSON.stringify(this.cubeVersion))
      } else if (path.includes("/docs/components/horizon")) {
        this.versions = JSON.parse(JSON.stringify(this.horizonVersion))
      } else {
        this.versions = JSON.parse(JSON.stringify(this.defaultVersion))
      }
      // 当前路径存在版本号
      if (this.nowVersion) {
        for (const key in this.versions) {
          if (this.versions[key].text === this.nowVersion) {
            this.version = key
            return
          }
        }
      } else {
        const arr = this.getVersionData()
        this.version = arr[0]
      }
    },
    getVersionData() {
      const arr = []
      for (const i in this.versions) {
        arr.push(i)
      }
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = 0; j < arr.length - i - 1; j++) {
          if (getAppVersionCompared(arr[j + 1], arr[j])) {
            const temp = arr[j]
            arr[j] = arr[j + 1]
            arr[j + 1] = temp
          }
        }
      }
      return arr
    },
    switchVersion(item) {
      if (item === this.version) return
      // 存在url时，直接跳转
      if (this.versions[item].link) {
        location.href = this.versions[item].link
      } else {
        location.href = `${location.origin}/${this.versions[item].text}${this.$route.path}`
      }
    }
  }
}
</script>
<style lang="scss">
.nav-dropdown__list.el-dropdown-menu {
  max-height: 80vh;
  overflow: auto;
  padding: var(--docs-dropdown-padding-horizontal) 0;

  // 选中值
  .active {
    color: var(--docs-dropdown-item-hover-font-color);
  }
}
</style>

<style lang="scss" scoped>
@import "./index.scss";
</style>
