export default {
  heroImage: "/images/hero.png",
  heroText: "otter微服务快速开发框架",
  tagline: "",
  actions: [
    {
      text: "微服务开发手册",
      link: "/docs/tutorial/develop/develop",
      type: "primary"
    },
    {
      text: "QuickStart",
      link: "/docs/tutorial/quickstart/quickstart",
      type: "secondary"
    }
  ],
  features: [
    {
      title: "Spring Cloud & Spring Alibaba",
      details: "微服务全家桶"
    },
    {
      title: "Mybatis Plus",
      details: "持久层"
    },
    {
      title: "微前端",
      details: "基于乾坤、ElementUI的前端开发组件"
    }
  ]
}
