const ua = navigator.userAgent.toLowerCase()
import tocData from "../../.cube/toc.json"

export const isWindows =
  ua.indexOf("win32") > -1 ||
  ua.indexOf("wow32") > -1 ||
  ua.indexOf("win64") > -1 ||
  ua.indexOf("wow64") > -1

export const hashRE = /#.*$/
export const extRE = /\.(md|html)$/
export const endingSlashRE = /\/$/
export const outboundRE = /^[a-z]+:/i
export const encodePath = (path) =>
  path
    .split("/")
    .map((item) => encodeURIComponent(item))
    .join("/")
export function normalize(path) {
  return decodeURI(path).replace(hashRE, "").replace(extRE, "")
}

export function getHash(path) {
  const match = path.match(hashRE)
  if (match) {
    return match[0]
  }
}

export function isExternal(path) {
  return outboundRE.test(path)
}

export function isMailto(path) {
  return /^mailto:/.test(path)
}

export function isTel(path) {
  return /^tel:/.test(path)
}

export function ensureExt(path) {
  if (isExternal(path)) {
    return path
  }
  const hashMatch = path.match(hashRE)
  const hash = hashMatch ? hashMatch[0] : ""
  const normalized = normalize(path)

  if (endingSlashRE.test(normalized)) {
    return path
  }
  // return normalized + ".html" + hash
  // encodePath 解决中文 URL 无法跳转问题
  return encodePath(normalized)
}

export function resolveNavLinkItem(linkItem) {
  return Object.assign(linkItem, {
    type: linkItem.items && linkItem.items.length ? "links" : "link"
  })
}

export function resolveNavLinks(links) {
  if (!links.length) return []
  return links.map((link) => {
    return Object.assign(resolveNavLinkItem(link), {
      items: link.items ? link.items.map(resolveNavLinkItem) : []
    })
  })
}

export function stripScript(content) {
  const result = content.match(/<(script)>([\s\S]+)<\/\1>/)
  return result && result[2] ? result[2].trim() : ""
}

export function stripStyle(content) {
  const result = content.match(/<(style)\s*>([\s\S]+)<\/\1>/)
  return result && result[2] ? result[2].trim() : ""
}

export function stripTemplate(content) {
  content = content.trim()
  if (!content) {
    return content
  }
  return content.replace(/<(script|style)[\s\S]+<\/\1>/g, "").trim()
}

/**
 * 根据路由获取匹配的 SideBar
 */
export const matchSideBar = (routePath, configObject) => {
  const items = routePath.split("/").filter((x) => x)
  const keys = Object.keys(configObject)
  const targetList = keys.map((x) => x.replace(/^\/|\/$/g, ""))
  let result = null

  while (items.length) {
    const a = items.splice(-1, 1)
    const p = items.join("/")
    const index = targetList.indexOf(p)
    if (index > -1) {
      result = configObject[keys[index]]
      break
    }
  }
  console.log(result, "result")
  result = setLevel(result, routePath)
  return result
}

/**
 * 给sidebar添加层级level
 */
export const setLevel = (array, routePath) => {
  const tocObj = JSON.parse(JSON.stringify(tocData))
  if (!Array.isArray(array)) return []
  const arr = JSON.parse(JSON.stringify(array))
  const flag = arr[0].children && typeof arr[0].children[0] === "string"
  const recursive = (arr, level = 0) => {
    level++
    return arr.map((v) => {
      // 如果是字符串，表示 当前侧边栏使用没有按钮的样式类型
      if (flag) {
        console.log(141)
        v.children = v.children.map((item) => {
          const data = {}
          data.text = tocObj[item][0].text // 因为为string类型的不存在text属性，取文章的第一个标题
          if (tocObj[item] && tocObj[item].length) {
            tocObj[item].shift()
            data.anchor = tocObj[item]
          }
          data.level = level
          v.hideMenuIcon = true // 添加是否隐藏按钮的标识
          data.link = item
          return data
        })
        v.level = level
        return v
      } else {
        if (tocObj[v.link] && tocObj[v.link].length) {
          tocObj[v.link].shift()
          v.anchor = tocObj[v.link]
        }
        // 优先级最高 如果是当前路由，默认展开
        if (routePath === v.link) v.collapse = false
        v.hideMenuIcon = false // 添加是否隐藏按钮的标识
        v.level = level
        // 存在collapse 就赋值优先级最高 ，其次是为第一等级的默认是false 展开，最后才是查看子级里是否有当前的路由的，有才会被赋值为false展开，然则true折叠
        v.collapse =
          "collapse" in v
            ? v.collapse
            : level === 1
              ? false
              : !getRouteFind(v.children, routePath) // 判断是否存在collapse属性
        const child = v.children
        if (child && child.length) recursive(child, level)
        return v
      }
    })
  }
  return recursive(arr)
}

// 查找路由是否存在
export const getRouteFind = (array, routePath) => {
  if (!Array.isArray(array)) return false
  let flag = false
  const find = (array) => {
    for (let index = 0; index < array.length; index++) {
      if (flag) return
      if (array[index].link === routePath) {
        flag = true
        return
      } else if (array[index].children && array[index].children.length) {
        find(array[index].children)
      }
    }
  }
  find(array)
  return flag
}

export const setClipboard = (code, cb) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(code).then(cb, () => { })
  } else {
    const copyelement = document.createElement("textarea")
    document.body.appendChild(copyelement)
    copyelement.value = code
    copyelement.select()
    document.execCommand("Copy")
    copyelement.remove()
    cb()
  }
}

/**
 * App 版本比较逻辑
 * 版本号格式 x.y.z,
 * https://semver.org/lang/zh-CN/
 * @param {string} latestVersion - 最新版本
 * @param {string} versionToBeCompared - 需要对比的版本号
 * x1.y1.z1 表示最新版本，x2.y2.z2 则是需要被对比的版本
 * 3: x1 > x2;
 * 2: x1 = x2, y1 > y2;
 * 1: x1 = x2, y1 = y2, z1 > z2;
 * 0: x1 = x2, y1 = y2, z1 = z2 及其他情况
 */
export function getAppVersionCompared(latestVersion, versionToBeCompared) {
  const currentVersion = versionToBeCompared
  const isMajor =
    ~~latestVersion.split(".")[0] > ~~currentVersion.split(".")[0]
  const isMinor =
    ~~latestVersion.split(".")[0] === ~~currentVersion.split(".")[0] &&
    ~~latestVersion.split(".")[1] > ~~currentVersion.split(".")[1]
  const isPatch =
    ~~latestVersion.split(".")[0] === ~~currentVersion.split(".")[0] &&
    ~~latestVersion.split(".")[1] === ~~currentVersion.split(".")[1] &&
    ~~latestVersion.split(".")[2] > ~~currentVersion.split(".")[2]
  const result = isMajor ? 3 : isMinor ? 2 : isPatch ? 1 : 0
  return result > 0
}

export const makeId = (n) => {
  let result = ""
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  for (let i = 0; i < n; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
