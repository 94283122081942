var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"markdown-container theme-default-content"},[_c('head',{staticClass:"markdown"}),_c('body',{staticClass:"markdown"},[_c('h1',{staticClass:"markdown"},[_vm._v("消息记录查询")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('p',{staticClass:"markdown"},[_vm._v("查询站内信，系统公告等多种类型的消息记录列表。")]),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"table markdown"},[_c('table',{staticClass:"markdown"},[_vm._m(6),_c('tbody',{staticClass:"markdown"},[_vm._m(7),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("messageTypeList")]),_c('td',{staticClass:"markdown"},[_vm._v("List"),_c('string',{staticClass:"markdown"})],1),_c('td',{staticClass:"markdown"},[_vm._v("消息类型列表")])])])])]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_c('p',{staticClass:"markdown"},[_vm._v("查询站内信，公告等多种类型消息记录统计（未读消息数, 已读消息数, 总消息数）")]),_vm._m(16),_vm._m(17),_vm._m(18),_c('div',{staticClass:"table markdown"},[_c('table',{staticClass:"markdown"},[_vm._m(19),_c('tbody',{staticClass:"markdown"},[_vm._m(20),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("messageTypeList")]),_c('td',{staticClass:"markdown"},[_vm._v("List"),_c('string',{staticClass:"markdown"})],1),_c('td',{staticClass:"markdown"},[_vm._v("消息类型列表")])])])])]),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"markdown"},[_c('li',{staticClass:"markdown"},[_vm._v("类名：RemoteMessageRecordService")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"markdown",attrs:{"id":"站内信公告等多种类型消息记录分页查询"}},[_c('a',{staticClass:"header-anchor markdown",attrs:{"href":"#站内信公告等多种类型消息记录分页查询"}},[_vm._v("#")]),_vm._v(" 站内信，公告等多种类型消息记录分页查询")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("描述")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("方法")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"markdown"},[_c('li',{staticClass:"markdown"},[_vm._v("RemoteMessageRecordService.page(MessageRecordQueryDTO queryDTO);")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("请求参数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"markdown"},[_c('tr',{staticClass:"markdown"},[_c('th',{staticClass:"markdown"},[_vm._v("字段")]),_c('th',{staticClass:"markdown"},[_vm._v("类型")]),_c('th',{staticClass:"markdown"},[_vm._v("描述")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("userId")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("用户id")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("返回参数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table markdown"},[_c('table',{staticClass:"markdown"},[_c('thead',{staticClass:"markdown"},[_c('tr',{staticClass:"markdown"},[_c('th',{staticClass:"markdown"},[_vm._v("字段")]),_c('th',{staticClass:"markdown"},[_vm._v("类型")]),_c('th',{staticClass:"markdown"},[_vm._v("描述")])])]),_c('tbody',{staticClass:"markdown"},[_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("id")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("主键")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("title")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("消息标题")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("content")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("消息内容")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("messageType")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("消息类型,0001-短信,0002-邮件,0003-企微,0004-公众号,0005-系统公告,0006-站内信")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("messageTypeName")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("消息类型名称")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("sender")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("发送人")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("请求示例")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"language- markdown"},[_c('pre',{pre:true,attrs:{"class":"markdown"}},[_c('code',{pre:true,attrs:{"class":"markdown"}},[_vm._v("MessageRecordQueryDTO queryDTO = new MessageRecordQueryDTO();\nqueryDTO.setUserId(\"1805786537497464603\");\nqueryDTO.setMessageTypeList(CollUtil.newArrayList(\"0005\"));\nResponse<PageDTO<MessageRecordDTO>> resultPage = remoteMessageRecordService.page(queryDTO, SecurityConstants.INNER);\n")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("返回示例")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"language- markdown"},[_c('pre',{pre:true,attrs:{"class":"markdown"}},[_c('code',{pre:true,attrs:{"class":"markdown"}},[_vm._v("{\n    \"code\": \"200\",\n    \"message\": \"OK\",\n    \"data\": {\n    \"records\": [\n    {\n        \"createTime\": \"2024-10-09 14:58:35\",\n        \"createUser\": \"1658748549897428992\",\n        \"createUserName\": null,\n        \"updateTime\": \"2024-10-09 14:58:35\",\n        \"updateUser\": \"1658748549897428992\",\n        \"createOrg\": null,\n        \"updateUserName\": null,\n        \"dataStatus\": null,\n        \"id\": \"1843908901931846003\",\n        \"title\": \"公告\",\n        \"content\": null,\n        \"messageType\": \"0005\",\n        \"messageTypeName\": \"系统公告消息\",\n        \"sender\": \"\"\n    },\n    \"total\": 48,\n    \"size\": 10,\n    \"current\": 0,\n    \"pages\": 5\n}\n")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"markdown",attrs:{"id":"站内信公告等多种类型消息记录统计查询"}},[_c('a',{staticClass:"header-anchor markdown",attrs:{"href":"#站内信公告等多种类型消息记录统计查询"}},[_vm._v("#")]),_vm._v(" 站内信，公告等多种类型消息记录统计查询")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("描述")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("方法")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"markdown"},[_c('li',{staticClass:"markdown"},[_vm._v("RemoteMessageRecordService.count(MessageRecordQueryDTO queryDTO);")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("请求参数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"markdown"},[_c('tr',{staticClass:"markdown"},[_c('th',{staticClass:"markdown"},[_vm._v("字段")]),_c('th',{staticClass:"markdown"},[_vm._v("类型")]),_c('th',{staticClass:"markdown"},[_vm._v("描述")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("userId")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("用户id")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("返回参数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table markdown"},[_c('table',{staticClass:"markdown"},[_c('thead',{staticClass:"markdown"},[_c('tr',{staticClass:"markdown"},[_c('th',{staticClass:"markdown"},[_vm._v("字段")]),_c('th',{staticClass:"markdown"},[_vm._v("类型")]),_c('th',{staticClass:"markdown"},[_vm._v("描述")])])]),_c('tbody',{staticClass:"markdown"},[_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("messageType")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("消息类型,0001-短信,0002-邮件,0003-企微,0004-公众号,0005-系统公告,0006-站内信")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("messageTypeName")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("消息类型名称")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("totalCount")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("总消息数")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("readCount")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("已读消息数")])]),_c('tr',{staticClass:"markdown"},[_c('td',{staticClass:"markdown"},[_vm._v("unreadCount")]),_c('td',{staticClass:"markdown"},[_vm._v("string")]),_c('td',{staticClass:"markdown"},[_vm._v("未读消息数")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("请求示例")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"language- markdown"},[_c('pre',{pre:true,attrs:{"class":"markdown"}},[_c('code',{pre:true,attrs:{"class":"markdown"}},[_vm._v("MessageRecordQueryDTO queryDTO = new MessageRecordQueryDTO();\nqueryDTO.setUserId(\"1805786537497464603\");\nqueryDTO.setMessageTypeList(CollUtil.newArrayList(\"0005\", \"0007\"));\nResponse<List<MessageRecordCountDTO>> result = remoteMessageRecordService.count(queryDTO, SecurityConstants.INNER);\n")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"markdown"},[_c('strong',{staticClass:"markdown"},[_vm._v("返回示例")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"language- markdown"},[_c('pre',{pre:true,attrs:{"class":"markdown"}},[_c('code',{pre:true,attrs:{"class":"markdown"}},[_vm._v("{\n    \"code\": \"200\",\n    \"message\": \"OK\",\n    \"data\": [\n        {\n            \"messageType\": \"0007\",\n            \"messageTypeName\": \"站内信\",\n            \"totalCount\": 10,\n            \"readCount\": 8,\n            \"unreadCount\": 2\n        },\n        {\n            \"messageType\": \"0005\",\n            \"messageTypeName\": \"系统公告消息\",\n            \"totalCount\": 4,\n            \"readCount\": 4,\n            \"unreadCount\": 0\n        }\n    ]\n}\n")])])])
}]

export { render, staticRenderFns }