<template>
  <div
    class="demo-block"
    :class="[
      blockClass,
      {
        hover: hideborder === 'true' ? '' : hovering,
      },
    ]"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <div v-if="$slots.default" class="demo-block-desc">
      <slot />
    </div>

    <div class="demo-block-demo">
      <slot name="source" />
    </div>

    <div
      v-if="hidecode === 'false'"
      ref="control"
      class="demo-block-footer"
      :class="{ 'is-fixed': fixedControl }"
    >
      <transition name="arrow-slide">
        <code-copy :code="code" type="icon" />
      </transition>
      <transition name="text-slide">
        <div
          class="icon-open"
          :class="{ expanded: isExpanded }"
          @click="isExpanded = !isExpanded"
          @mouseleave="iconColor = isExpanded ? '#409eff':'#ccc'"
          @mouseenter="iconColor = '#409eff'"
        >
          <cube-tooltip :content="isExpanded ? '隐藏代码' : '显示代码'" placement="top">
            <cube-icon name="cube-expand-left-and-right-outline" :color="iconColor" width="20px" height="20px" />
          </cube-tooltip>
        </div>
      </transition>
    </div>

    <div v-if="hidecode === 'false'" ref="meta" class="demo-block-source">
      <div class="highlight">
        <slot name="highlight" />
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
// import compoLang from "../i18n/component.json"
import CodeCopy from "@/components/CodeCopy/index.js"
import { stripScript, stripStyle, stripTemplate } from "../../utils"
const Prism = require("prismjs")
export default {
  components: {
    CodeCopy
  },
  props: {
    hidecode: {
      type: String,
      default: "false"
    },
    hideborder: {
      type: String,
      default: "false"
    }
  },
  data() {
    return {
      codepen: {
        script: "",
        html: "",
        style: ""
      },
      hovering: false,
      isExpanded: false,
      fixedControl: false,
      scrollParent: null,
      hasChange: false,
      code: "",
      copySuccess: false,
      iconColor: "#ccc"
    }
  },

  computed: {
    blockClass() {
      return [
        `demo-${this.lang} demo-${this.$router.currentRoute.path
          .split("/")
          .pop()}`,
        {
          "hide-border": this.hideborder === "true",
          "hide-code": this.hidecode === "true",
          "no-desc": !this.$slots.default && this.hideborder !== "true"
        }
      ]
      // return;
    },

    codeArea() {
      return this.$el.getElementsByClassName("demo-block-source")[0]
    },

    codeAreaHeight() {
      return this.$el.getElementsByClassName("highlight")[0].clientHeight
    }
  },

  watch: {
    isExpanded(val) {
      if (this.isExpanded) {
        console.log(12412)
        this.iconColor = "#409eff"
      } else {
        this.iconColor = "#ccc"
      }
      if (!this.hasChange) {
        const el = this.$slots.highlight[0].elm.children[0]
        el.innerHTML = Prism.highlight(el.innerText, Prism.languages.html)
        this.hasChange = true
      }
      this.codeArea.style.height = val ? `${this.codeAreaHeight + 1}px` : "0"
      if (!val) {
        this.fixedControl = false
        this.$refs.control.style.left = "0"
        this.removeScrollHandler()
        return
      }
      setTimeout(() => {
        this.scrollParent = document.querySelector(
          ".page-component__scroll > .el-scrollbar__wrap"
        )
        this.scrollParent &&
          this.scrollParent.addEventListener("scroll", this.scrollHandler)
        this.scrollHandler()
      }, 200)
    }
  },

  created() {
    const highlight = this.$slots.highlight
    if (highlight && highlight[0]) {
      let code = ""
      let cur = highlight[0]
      if (cur.tag === "pre" && cur.children && cur.children[0]) {
        cur = cur.children[0]
        if (cur.tag === "code") {
          code = cur.children[0].text
        }
      }
      if (code) {
        this.code = code
        this.codepen.html = stripTemplate(code)
        this.codepen.script = stripScript(code)
        this.codepen.style = stripStyle(code)
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      const highlight = this.$el.getElementsByClassName("highlight")[0]
      if (this.$el.getElementsByClassName("demo-block-desc").length === 0 && highlight) {
        highlight.style.width = "100%"
        highlight.borderRight = "none"
      }
    })
  },

  beforeDestroy() {
    this.removeScrollHandler()
  },

  methods: {
    scrollHandler(code) {
      // const { top, bottom, left } = this.$refs.meta.getBoundingClientRect()
      // this.fixedControl =
      //   bottom > document.documentElement.clientHeight &&
      //   top + 44 <= document.documentElement.clientHeight
      // this.$refs.control.style.left = this.fixedControl ? `${left}px` : "0"
    },

    removeScrollHandler() {
      this.scrollParent &&
        this.scrollParent.removeEventListener("scroll", this.scrollHandler)
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
